import React, { useState, useEffect, useMemo } from 'react';
import api, { newApi } from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import Editer from 'Components/Organisms/DefaultEditor';
import styled from 'styled-components';
import articlesConfig from 'Modules/ModelConfigs/Pokemon/Articles.js';
import enviroment from 'Modules/enviroment.js';
import ArchetypeSummaryArticleBodyForm from 'Components/Organisms/ArchetypeSummaryArticleBodyForm.jsx';

const ArticleEditor = (props) => {
  const [contentState, setContentState] = useState();

  useEffect(() => {
		props.setter({ ...props.article, body: JSON.stringify(contentState) });
	}, [contentState])

  return(
    <Editer
      name="body"
      rawStateString={props.article.body}
      cards={props.cards}
      readOnly={false}
      setContentState={setContentState}
    />
  );
}

const PreviewButton = (props) => {
  const onClick = (event) => {
    event.preventDefault();
    api.httpPost(`/admin/${props.cardTitle.name}/articles/make_preview`, {article: props.article}, (response) => {
      if (response.status == 200) {
        window.open(`${enviroment.userFrontOrigin}/pokemon/articles/preview/${response.data.body.id}`);
      }
    })
  }

  return(
    <button style={{marginBottom: '20px'}} onClick={onClick} >プレビュー</button>
  );
}

const getFormPartsConfig = (cardTitle, categories, tags, editor, previewButton) => {
  const categoryOptions = [];
  const categoryOptionValues = [];

  categories.forEach((category) => {
    categoryOptions.push(category.name);
    categoryOptionValues.push(category.id);
  })

  const tagRelationsList = [];
  const tagDisplayOptions = [];

  tags.forEach((tag) => {
    tagRelationsList.push({[`${cardTitle.name}_article_tag_id`]: tag.id});
    tagDisplayOptions.push(tag.name);
  })

  return([
    {
      labelText: '公開状態',
      key: 'is_open',
      type: 'radioCombi',
      labelText: '公開状態',
      options: ['公開', '非公開'],
      optionValues: ['true', 'false'],
    },
    {
      labelText: '記事タイトル',
      key: 'title',
      type: 'text',
    },
    {
      labelText: '記事カテゴリ',
      key: `${cardTitle.name}_article_category_id`,
      type: 'select',
      options: categoryOptions,
      optionValues: categoryOptionValues,
    },
    {
      labelText: 'ライター名',
      key: 'author',
      type: 'select',
      options: articlesConfig.authors,
      optionValues: articlesConfig.authors,
    },
    {
      labelText: 'サムネ画像',
      key: 'image_key',
      type: 'file',
    },
    {
      labelText: '注目カード1',
      key: 'picked_up_card_ids_0',
      type: 'text',
    },
    {
      labelText: '注目カード2',
      key: 'picked_up_card_ids_1',
      type: 'text',
    },
    {
      labelText: '注目カード3',
      key: 'picked_up_card_ids_2',
      type: 'text',
    },
    {
      labelText: '注目カード4',
      key: 'picked_up_card_ids_3',
      type: 'text',
    },
    {
      labelText: '注目カード下ボタンのテキスト(18文字くらいまで、それ超えると崩れる可能性ある)',
      key: 'reccomend_text',
      type: 'text',
    },
    {
      labelText: '注目カード下ボタンのURL',
      key: 'reccomend_url',
      type: 'text',
    },
    {
      labelText: '記事タグ',
      key: 'tag_relations_attributes',
      type: 'checkboxListWithLocalSearch',
      list: tagRelationsList,
      displayOptions: tagDisplayOptions,
    },
    {
      type: 'custom',
      component: previewButton,
    },
    {
      type: 'custom',
      component: editor,
    },
  ]);
}

const NewArticle = (props) => {
	const cardTitle = useCardTitle();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [articleTags, setArticleTags] = useState([]);
  const [archetypeSummaryArticleCategory, setArchetypeSummaryArticleCategory] = useState({});
  const [cards, setCards] = useState([]);
  const [article, setArticle] = useState({
    id: '',
    title: '',
    pokemon_article_category_id: null,
    is_open: false,
    image_key: '',
    preview_source: '',
    body: '',
    author: articlesConfig.authors[0],
    tag_relations_attributes: [],
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/article_categories`, {limit: 300}), (response) => {
      const newCategories = [...response.body];
      newCategories.unshift({id: '', name: '未指定'});
      setCategories(newCategories);
    });

    api.httpGet(api.addQuery(`/${cardTitle.name}/article_tags`, {limit: 300}), (response) => {
      setArticleTags(response.body);
    });

    api.httpGet(`/${cardTitle.name}/article_categories/archetype_summary`, (response) => {
      setArchetypeSummaryArticleCategory(response.body);
    })

  }, [])

  useEffect(() => {
    const limit = 1000;
    const currentPage = (cards.length / limit) + 1;
    const query = {
      limit: limit,
      page: currentPage,
      to_json_option: {
        methods: ['image_source'],
        only: ['id', 'name']
      },
    }

    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/cards`, query), (response) => {

      if (!loading) {
        return;
      }

      const newCards = [...cards, ...response.body];

      if (currentPage >= response.last_page) {
        setLoading(false);
      }
      setCards(newCards);
    });
  }, [cards])

  const handleInputValue = (event) => {
    let value;
    let targetName = event.target.name
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else if (targetName.includes('picked_up_card_ids')) {
      if (!article.picked_up_card_ids) {
        value = [];
      } else {
        value = [...article.picked_up_card_ids];
      }
      const index = targetName.split('_').pop();
      value[index] = event.target.value;
      targetName = 'picked_up_card_ids';
    } else {
      value = event.target.value
    }
    setArticle(setNestedJsonValue({...article}, targetName, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("article", JSON.stringify(article));
    data.append("file", article.image_key);
    api.httpPost(`/admin/${cardTitle.name}/articles`, data, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/articles`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/articles/new`)
      }
    })
  }

  const formPartsConfig = useMemo(() => {
    const isArchetypeSummary = (archetypeSummaryArticleCategory.id == article[`${cardTitle.name}_article_category_id`])
    const editor = isArchetypeSummary ? <ArchetypeSummaryArticleBodyForm setArticle={setArticle} cardTitle={cardTitle} article={article} cards={cards} /> : <ArticleEditor setter={setArticle} article={article} cards={cards} />;
    return(getFormPartsConfig(cardTitle, categories, articleTags, editor, <PreviewButton cardTitle={cardTitle} article={article} />));
  }, [cardTitle, categories, articleTags, setArticle, article, cards, archetypeSummaryArticleCategory.id])

  if (loading) {
    return(<div>読み込み中...現在{cards.length}件取得中</div>);
  }

  return(
    <DefaultFormTemplate
      className={props.className}
      pageTitle={`【${cardTitle.name}】記事:新規作成`}
      record={article}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

const StyledNewArticle = styled(NewArticle)`
.DefaultForm > .CheckBoxListWithLocalSearch {
  position: fixed;
  bottom: 12px;
  width: 30%;
  right: 6%;
  bottom: 36%;
  height: 200px;
  max-height: 200px;
  overflow: scroll;
}
`;

export default StyledNewArticle